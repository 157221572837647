import React from 'react';

import './MailingListButton.css';

const MailingListButton = () => (
    <div id="mailinglist">
        <a className="mailinglist-button" href='https://docs.google.com/forms/d/e/1FAIpQLSc8-A3Qi1Y2wPggEdDkupZKccV1x6_ox329twIUTnMkYBA6jA/viewform?usp=sf_link' target="_blank" rel="noopener noreferrer">Join the <i>DISSONANCE</i> MVP Mailing List</a>
    </div>
);

export default MailingListButton;