import React from 'react';

import './SupportUs.css';
import { DonateButton } from '../../components';
import { Collaborators } from '../../container';

const SupportUs = () => (
    <div className="app__bg app__supportus-container" id="supportUs">
        <div className="app__bg app__supportus-title">
            <h1 className="headtext__cormorant">Support Us</h1>
        </div>
        <div className="app__bg section__padding" var={{padding:0}}>
            <div className='app__wrapper_support' var={{padding:0, margin:0}}>
                <div className="app__supportus-body">
                    <p className="p__opensans_profile">
                        After the tumult of the last few years, Dissonance is the honest dialogue American needs to hear.
                        <br/>
                        <br/>
                        Help us bring this exciting new play to communities everywhere.
                    </p>
                    <p className='p__opensans_support'>
                        Be a part of the revolution with your generous contribution.
                    </p>
                </div>
            </div>
        </div>
        <div className='app__bg donate_container'>
            <DonateButton/>
        </div>         
    </div>
    
);

export default SupportUs;