import React from 'react';

import './TourHistory.css';
import { DonateButton } from '../../components';

const TourHistory = () => (
    <div className="app__bg app__tourhistory-container" id="tourhistory">
        <div className="app__bg app__tourhistory-title">
            <h1 className="headtext__cormorant">Tour History</h1>
        </div>
        <div className="app__bg section__padding" var={{padding:0}}>
            <div className='app__wrapper_tourhistory' var={{padding:0, margin:0}}>
                <div className="app__tourhistory-body">
                    <ul className="tourhistory_listcontainer">
                        <li className="tourhistory_listitem">Reading for an invited audience at University of West Florida - Pensacola, FL (April 2022)</li>
                        <li className="tourhistory_listitem">Workshop Production at Florida A&M University - Tallahassee, FL (August 2022)</li>
                        <li className="tourhistory_listitem">North Florida Tour launched at Gordon Theatre - Pensacola, FL (September 2022)</li>
                        <li className="tourhistory_listitem">Production at Center for Fine & Performing Arts - Pensacola, FL (January 2023)</li>
                        <li className="tourhistory_listitem">Production at Emerald Coast Theatre Company - Miramar Beach, FL (January 2023)</li>
                        <li className="tourhistory_listitem">Production at Pensacola Little Theatre - Pensacola, FL (January 2023)</li>
                        <li className="tourhistory_listitem">Production at Bronx Community College - New York City, NY (April 2023)</li>
                        <li className="tourhistory_listitem">Production at The Essential Theatre - Washington, D.C. (May 2023)</li>
                        <li className="tourhistory_listitem">Production at Mattie Kelly Arts Center - Niceville, FL (July 2023)</li>
                        <li className="tourhistory_listitem">Production at Reilly Arts Center - Ocala, FL (August 2023)</li>
                        <li className="tourhistory_listitem">Production at The Ritz Theater/Wayne Densch Performing Arts Center - Sanford, FL (August 2023)</li>
                        <li className="tourhistory_listitem">Production at Mississippi Gulf Coast Community College - Gulfport, MS (February 2024)</li>
                        <li className="tourhistory_listitem">Production at the Arts Garage - Delray Beach, FL (June 2024)</li>
                        <li className="tourhistory_listitem">Production at the International Black Theatre Festival - Winston-Salem, NC (July 2024)</li>
                    </ul>
                </div>
            </div>
        </div>     
    </div>
    
);

export default TourHistory;