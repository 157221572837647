import React from "react";

import {Creators, Footer, Gallery, Header, UpcomingShows, SupportUs, Articles, Contact, Collaborators} from "../../container";
import {Navbar, TicketButton, SocialIcons, DonateFixed } from "../../components";
import "../../App.css";

const HomePage = () => {
    return (
        <div className="page">
            <Header/>
            <Footer/>
        </div>
    );
};

export default HomePage;