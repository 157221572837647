import React from "react";

import {Creators, Footer, Gallery, Header, UpcomingShows, SupportUs, Articles, Contact, Collaborators, About} from "../../container";
import {Navbar, TicketButton, SocialIcons, DonateFixed } from "../../components";
import "../../App.css";

const AboutPage = () => {
    return (
        <div className="page">
            <About />
            <Footer/>
        </div>
    );
};

export default AboutPage;