import React from 'react';
import './Collaborators.css';
import { images } from '../../constants';

const Collaborators= () => (
    <div className="app__bg app__collaborators-container" id="collaborators">
        
        <div className="app__bg section__padding" var={{padding:0}}>
            <div className='app__wrapper_collaborators' var={{padding:0, margin:0}}>
                <div className="app__collaborators-body">
                    <p className="p__opensans_collaborators">
                        Special thanks to everyone who has supported Dissonance's journey so far!
                    </p>
                    <div className="app__wrapper_collabimg">
                        <img src={images.collaborators} className="contributorimg"loading="lazy" alt="collaborator_img" />
                    </div>
                </div>
            </div>
        </div>      
    </div>
    
);

/*

<div className="app__bg app__collaborators-title">
            <h1 className="headtext__cormorant">Collaborators</h1>
        </div>

*/
export default Collaborators;