import React from 'react';
import Typewriter from "typewriter-effect";
import './QuotesCarousel.css';

function QuotesCarousel() {
    return (
        <div className="QuotesCarouselContainer">
            <Typewriter
                options={{loop:true, delay: 20, deleteSpeed:1 }}
                onInit={(typewriter) => {
                    typewriter
                        .typeString("\"It’s a play, a conversation, an expression of national healing.\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .typeString("\"Well-written, clear, powerful, touching, funny, thought-provoking, and you were both wonderful! I truly believe that it's a story that needs to be heard.\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .typeString("\"This show was created out of powerful and painful conversations, but your desire to help find some \"light at the end of the tunnel\" brought forth this touchingly raw piece. Many need to hear and see it! You present the hurt and anger that we feel so well. I applaud you!\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .typeString("\"A funny, provocative, and thought-provoking dramedy addressing today’s issues. In the highly politicized, polarized and racially charged society in which we live, it’s encouraging to see these women have an open and honest conversation about race and walk away as friends.\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .typeString("\"It created deeper conversation with the women I attended with, even the young woman who walked us to our car.\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .typeString("\"I’m still processing this heartwarming, masterful, and complex piece. For some reason, I can’t seem to stop feeling. What a show!!\"")
                        .pauseFor(6000)
                        .deleteAll(1)
                        .start();
                }}
            />
        </div>
    );
}
export default QuotesCarousel;