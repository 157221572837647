import React from "react";

import { SupportUs, Collaborators, Footer } from "../../container";
import "../../App.css";

const SupportPage = () => {
    return (
        <div className="page">
            <SupportUs/>
            <Collaborators/>
            <Footer/>
        </div>
    );
};

export default SupportPage;