import React from 'react';
import { useCollapse } from 'react-collapsed';
import './Reviews.css';
import {MdArrowForward, MdArrowUpward, MdArrowDownward, MdExpandMore, MdExpandLess} from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { Item } from '../../components';
import { images, pressimages, data } from '../../constants';

function Reviews() {
        const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
        const expandString = <div class="headerContent" style={{backgroundColor:"rgba(42, 39, 39, 0.449)", borderColor:"var(--color-golden)", borderRadius:"15px", padding:"1rem", border:"30px"}}><h1 className="headtext__cormorant-review-title" >Theatre Reviews</h1><div class="expandIcon"><MdExpandMore style={{fontSize:"50px"}}/></div></div>
        const collapseString = <div class="headerContent" style={{color:"var(--color-darkblack)", backgroundColor:"var(--color-golden)", borderColor:"var(--color-golden)", borderRadius:"15px" }}><h1 className="headtext__cormorant-review-title"style={{color:"var(--color-black)"}}>Theatre Reviews</h1><div class="expandIcon"><MdExpandLess style={{fontSize:"50px"}}/></div></div>
    return(
        <div className="app__bg app__reviews section__padding_links" id="reviews">
            <div className="collapsible">
                <div className="header" {...getToggleProps()}>
                    {isExpanded ? collapseString : expandString }
                </div>
                <div {...getCollapseProps()}>
                    <div className="app__reviews-feature-container">
                        <div class="grid">
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceBroadwayWorld} alt="Broadway World Review" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Broadway World</h1>
                                        <p class="card__text">Essential Theatre's <i>DISSONANCE</i> An Essential Conversation About What Divides Us</p>
                                        <a class="card__btn" href="https://www.broadwayworld.com/washington-dc/article/Review-Essential-Theatres-DISSONANCE-An-Essential-Conversation-About-What-Divides-Us-20230602" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceMdTheatreGuide} alt="MD Theatre Guide Review" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">MD Theatre Guide</h1>
                                        <p class="card__text"><i>DISSONANCE</i> presented by The Essential Theatre at Anacostia Arts Center</p>
                                        <a class="card__btn" href="https://mdtheatreguide.com/2023/06/theatre-review-dissonance-presented-by-the-essential-theatre-at-anacostia-arts-center/" target="_blank" rel="noopener noreferrer">Review<span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>                          
                        </div>    
                    </div> 
                </div>
            </div>       
        </div>
    );
}
export default Reviews;