import React from "react";

import { Gallery, Footer } from "../../container";
import "../../App.css";

const GalleryPage = () => {
    return (
        <div className="page">
            <Gallery/>
            <Footer/>
        </div>
    );
};

export default GalleryPage;