import React from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import {MdArrowForward } from "react-icons/md";
import { images } from '../../constants';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import './Gallery.css';


const Gallery = () => {
    
    const scrollRef = React.useRef(null);

    const scroll = (direction) => {
        const { current } = scrollRef;

        if (direction === 'left') {
            current.scrollLeft -= 300;
        } else {
            current.scrollLeft += 300;
        }
    };

    return (
        <div className="app__bg app__gallery flex__center" id="gallery">
            <div className="app__gallery-title">
                <h1 className="headtext__cormorant">Gallery</h1>
            </div>
            <div className="app__gallery-images">
                <div className="app__gallery-images_container" ref={scrollRef}>
                    {[images.diss_promo_1, images.diss_promo_2, images.diss_promo_5, images.diss_promo_4, images.diss_promo_3, images.disflyer, images.belmont1].map((image, index) => (
                        <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
                        <a href={image}><img src={image} alt="gallery_image" loading='lazy'/></a>
                        </div>
                    ))}
                </div>
                <div className="app__gallery-images_arrows">
                    <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
                    <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
                </div>
            </div>
        </div>
    );
};

export default Gallery;