import React from 'react';
import { useCollapse } from 'react-collapsed';
import './Interviews.css';
import {MdArrowForward, MdArrowUpward, MdArrowDownward, MdExpandMore, MdExpandLess} from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { Item } from '../../components';
import { images, pressimages, data } from '../../constants';

function Interviews() {
        const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
        const expandString = <div class="headerContent" style={{backgroundColor:"rgba(42, 39, 39, 0.449)", borderColor:"var(--color-golden)", borderRadius:"15px", padding:"1rem", border:"30px"}}><h1 className="headtext__cormorant-interview-title" ><i>DISSONANCE</i> in the News</h1><div class="expandIcon"><MdExpandMore style={{fontSize:"50px"}}/></div></div>
        const collapseString = <div class="headerContent" style={{color:"var(--color-darkblack)", backgroundColor:"var(--color-golden)", borderColor:"var(--color-golden)", borderRadius:"15px" }}><h1 className="headtext__cormorant-interview-title"style={{color:"var(--color-black)"}}><i>DISSONANCE</i> in the News</h1><div class="expandIcon"><MdExpandLess style={{fontSize:"50px"}}/></div></div>
    return(
        <div className="app__bg app__interviews section__padding_links" id="interviews">
            <div className="collapsible">
                <div className="header" {...getToggleProps()}>
                    {isExpanded ? collapseString : expandString }
                </div>
                <div {...getCollapseProps()}>
                    <div className="app__interviews-feature-container">
                        <div class="grid">
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceWEAR} alt="WEAR News Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">WEAR Channel 3 News</h1>
                                        <p class="card__text">Interview with the creators of <i>DISSONANCE</i></p>
                                        <a class="card__btn" href="https://www.youtube.com/watch?v=NhB9Dnl_pRo&ab_channel=WEARSales" target="_blank" rel="noopener noreferrer">Watch <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceFox10} alt="Fox 10 News Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Fox 10 News</h1>
                                        <p class="card__text"><i>DISSONANCE</i></p>
                                        <a class="card__btn" href="https://www.fox10tv.com/video/2023/01/12/dissonance/" target="_blank" rel="noopener noreferrer">Watch<span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceWCJB20} alt="WCJB TV 20 News" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">WCJB TV 20 News</h1>
                                        <p class="card__text"><i>DISSONANCE</i> Play Returns to FL</p>
                                        <a class="card__btn" href="https://www.youtube.com/watch?v=5AT4kN1ge-k" target="_blank" rel="noopener noreferrer">Watch<span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceFox10_2} alt="Fox 10 News" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Fox 10 News</h1>
                                        <p class="card__text"><i>DISSONANCE</i> at Mississippi Gulf Coast Community College</p>
                                        <a class="card__btn" href="https://www.fox10tv.com/video/2024/01/08/dissonance-mississippi-gulf-coast-community-college/" target="_blank" rel="noopener noreferrer">Watch<span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceWLOX} alt="WLOX News" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">WLOX News</h1>
                                        <p class="card__text"><i>DISSONANCE</i> to be performed at MGCCC Harrison County campus</p>
                                        <a class="card__btn" href="https://www.wlox.com/video/2024/02/02/dissonance-be-performed-mgccc-harrison-county-campus/" target="_blank" rel="noopener noreferrer">Watch<span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                    </div>    
                </div> 
            </div>
        </div>       
    </div>
    );
}
export default Interviews;