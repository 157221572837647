import React from "react";
import { GiHamburgerMenu } from "react-icons/gi"
import { MdOutlineClose } from "react-icons/md"
import { NavLink } from "react-router-dom";

import images from "../../constants/images"
import "./Navbar.css"

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = React.useState(false);
    return (
        <nav className="app__navbar">
            <div className="app__navbar-smallscreen">
                <GiHamburgerMenu color="#fff" fontSize={40} onClick={() => setToggleMenu(true)}/>
                
                {toggleMenu && (
                    <div className="app__navbar-smallscreen_overlay flex__center slide-bottom"> 
                        <MdOutlineClose fontSize={40} className="overlay__close" onClick={() => setToggleMenu(false)} />
                        <ul className="app__navbar-smallscreen_links">
                            <li className="p__opensans"><NavLink to="/" onClick={()=> setToggleMenu(false)}>Home</NavLink></li>
                            <li className="p__opensans"><NavLink to="/about" onClick={()=> setToggleMenu(false)}>About</NavLink></li>
                            <li className="p__opensans"><NavLink to="/tour" onClick={()=> setToggleMenu(false)}>Tour</NavLink></li>
                            <li className="p__opensans"><NavLink to="/team" onClick={()=> setToggleMenu(false)}>Team</NavLink></li>
                            <li className="p__opensans"><NavLink to="/media" onClick={()=> setToggleMenu(false)}>Media</NavLink></li>
                            <li className="p__opensans"><NavLink to="/contact" onClick={()=> setToggleMenu(false)}>Contact</NavLink></li>
                        </ul>
                    </div>
                )} 
            </div>
            <div className="app__navbar-logo">
                <img src = {images.dislogo} alt="app-logo"/>
            </div>
            <ul className="app__navbar-links">
                <li className="p__opensans"><NavLink to="/">Home</NavLink></li>
                <li className="p__opensans"><NavLink to="/about">About</NavLink></li>
                <li className="p__opensans"><NavLink to="/tour">Tour</NavLink></li>
                <li className="p__opensans"><NavLink to="/team">Team</NavLink></li>
                <li className="p__opensans"><NavLink to="/media">Media</NavLink></li>
                <li className="p__opensans"><NavLink to="/contact">Contact</NavLink></li>
            </ul>
        </nav>
    );
};

/*
<li className="p__opensans"><NavLink to="/about" onClick={()=> setToggleMenu(false)}>About</NavLink></li>
*/
export default Navbar;