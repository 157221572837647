// Theatre Reviews
import dissonanceBroadwayWorld from "../assets/diss-broadwayworld.avif";
import dissonanceMdTheatreGuide from "../assets/diss-mdtheatreguide.avif";

// News Articles
import dissonanceOrlandoWeekly from "../assets/diss-orlando-weekly.avif";
import dissonanceParkAveMagazine from "../assets/diss-park-ave-mag.jpeg";
import dissonanceWUWF from "../assets/diss-wuwf.jpeg";
import dissonanceTallahasseeDemocrat from "../assets/diss-tallahassee-democrat.jpeg";
import dissonancePNJ from "../assets/diss-pnj.jpeg";
import dissonanceOrlandoSentinel from "../assets/diss-orlando-sentinel.jpeg";
import dissonanceNWFDailyNews from "../assets/diss-nwfdailynews.avif";
import dissonanceISSU from "../assets/diss-issu.avif";

// Podcasts
import dissonanceblankproject from '../assets/diss-blank-project.avif';
import dissonancebBlackTheatreNetwork from '../assets/diss-blacktheatrenetwork.avif';
import dissonanceWomenIgnitingChange from '../assets/diss-womenignitingchange.avif';
import dissonanceFriendshipable from '../assets/diss-friendshipable.avif';
import dissonanceLegacyTalks from '../assets/diss-legacytalks.avif';
import dissonanceOnstageOffstage from '../assets/diss-onstageoffstage.avif';

// Video Interviews
import dissonanceWEAR from '../assets/diss-wear.avif';
import dissonanceFox10 from '../assets/diss-fox10.avif';
import dissonanceWCJB20 from '../assets/diss-wcjbtv.avif';
import dissonanceFox10_2 from '../assets/diss-fox10-2.avif';
import dissonanceWLOX from '../assets/diss-wlox.avif';

export default {

    // Theatre Reviews
    dissonanceBroadwayWorld,
    dissonanceMdTheatreGuide,

    //News Articles
    dissonanceOrlandoWeekly,
    dissonanceParkAveMagazine,
    dissonanceWUWF,
    dissonanceTallahasseeDemocrat,
    dissonancePNJ,
    dissonanceOrlandoSentinel,
    dissonanceNWFDailyNews,
    dissonanceISSU,

    //Podcasts
    dissonanceblankproject,
    dissonancebBlackTheatreNetwork,
    dissonanceWomenIgnitingChange,
    dissonanceFriendshipable,
    dissonanceLegacyTalks,
    dissonanceOnstageOffstage,

    // Video Interviews
    dissonanceWEAR,
    dissonanceFox10,
    dissonanceWCJB20,
    dissonanceFox10_2,
    dissonanceWLOX
};