import React from 'react';

import './About.css';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const About = () => (
    <div className="app__bg app__about" id="about">
        <div className="app__bg app__about-title">
            <h1 className="headtext__cormorant">About <i>DISSONANCE</i></h1>
        </div>
        <div className="app__about_about_items">
            <div className="app__bg app__wrapper section__padding">
                <div className="about_video_container">
                    <div className="headtext__cormorant"style={{fontSize:"55px"}}>Promotional Video</div>
                    <iframe className="about_youtube" style={{minWidth:"200px" }} loading='lazy' src="https://www.youtube.com/embed/hFOfcdOMJ4E?si=qWuBJAtyfySfiZwW" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen></iframe>
                </div>
                <div className="app__wrapper_info">
                    <div className="headtext__cormorant">Synopsis</div>
                    <div className="app__about-content">
                        <p className="p__opensans_about"><i>DISSONANCE</i> is a 90-minute two-person play about race, love, and friendship.</p>
                        <p className="p__opensans_about">Angela, a Black woman, and Lauren, a White woman, have been friends for 20 years. They met in graduate school, became godmothers to each other’s children, and most recently, started a new business, but they have never had a candid conversation about race . . . until now.</p>
                        <p className="p__opensans_about">As they open their cafe in a historically Black neighborhood, they uncover deeply held perceptions about race and have to decide if being brutally honest and devastatingly vulnerable is worth the risk of their friendship’s potential end.</p>
                        <p className="p__opensans_about">On the heels of George Floyd’s murder and the summer of 2020, these women allow themselves to have the hard conversation and become closer than ever as a result of it.</p>
                    </div>
                </div>
            </div>
            <div className="app__bg section__padding" style={{textAlign:'center', alignContent:'center', alignItems:'center'}}>
                <div className="headtext__cormorant">Three Cord Productions</div>
                <div className="app__bg app__wrapper threecord_container">
                    <div className="app__about-content_threecord">
                        <p className="p__opensans_about_threecord">A cord of three strands is not easily broken.</p>
                        <p className="p__opensans_about_threecord">Three Cord Productions exists to promote unity. We bring relevant, powerful, professional theatre to communities, universities, and organizations.</p>
                        <p className="p__opensans_about_threecord">We believe that theatre can enlighten, educate, inspire, and activate.</p>
                        <p className="p__opensans_about_threecord_bold">In short, we believe a play can change the world. We are part of that change.</p>
                    </div>
                    <div className='about_image_container'>
                        <img src={images.threecordprod} className="threecordimg"loading="lazy" alt="Three Cord Productions" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default About;