import React from "react";
import {render} from "react-dom";

import "./index.css"
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";
<head>
    <link rel="icon" as="favico" href="./assets/Dfavico.avif"></link>
    <link rel="preload" as="image" href="./assets/Dissonance-Poster.avif"/>
</head>


const Root = () => <BrowserRouter><App /></BrowserRouter>; 
render(<Root />, document.getElementById("root"));
/*const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<BrowserRouter><App/></BrowserRouter>);*/