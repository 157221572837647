import React from "react";

import { Contact, Footer } from "../../container";
import "../../App.css";

const ContactPage = () => {
    return (
        <div className="page">
            <Contact/>
            <Footer/>
        </div>
    );
};

export default ContactPage;