import React from 'react';

import './ContactButton.css';


function decryptEmail() {
    var address = 'contact@dissonanceplay.com';
    window.location.href = "mailto:" + address;
}

const ContactButton = () => (
    <div id="contact-button-container">
        <button className="contact-button" onClick={decryptEmail}><a href='#footer'>Contact Us</a></button>
    </div>
);

export default ContactButton;