import React from 'react';
import './ReviewBlurbs.css';

const ReviewBlurbs = () => (
    <div className="reviewblurbs_container">
        <div className="app__reviewblurbs-quotes">
            <p className="p__opensans_profile" style={{ color: 'var(--color-golden)', textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
                “Dissonance” is a heart-warming, gut-wrenching, soul-deep experience that can open a lot of eyes and, more importantly, a lot of hearts.”
            </p>
            <p className="p__opensans_profile" style={{ color: 'var(--color-golden)',  textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
                “The genius of “Dissonances” is the way that it reveals, and then gently dismantles, those walls we erect around ourselves, those unconscious fears that prevent us from really communicating and empathizing with people different from ourselves.” 
            </p>
            <p className="p__opensans_profile" style={{ color: 'var(--color-golden)',  textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
            “Both Duncan and Sandell create human beings we recognize instantly—their virtues intact, their flaws visible but never damning.”
            </p>
            <p className="p__opensans_profile" style={{ color: 'var(--color-golden)',  textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
                “Get off your couches, tell Netflix and Hulu you’ll see them later, you’ve got business to attend to..” <br/> <p style={{color:'white'}}>- Andrew White from Broadway World</p>
            </p>
            <p className="p__opensans_profile" style={{ color: 'var(--color-golden)',  textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
                “…an emotional powerhouse of a production…This play lands within my top three for the season…riveting…You don’t want to miss this one.”
            </p>
            <p className="p__opensans_profile " style={{ color: 'var(--color-golden)',  textAlign:'center', alignItems:'center', fontSize:'20px', marginTop:"1.5rem"}}>
                “This really is a roll up your sleeves, dig in for the long haul, and show the whole of your heart moment for both of the actors—and they deliver tenfold.” <br/> <p style={{color:'white'}}>- Anne Valentino from MD Theatre Guide</p>
            </p>
        </div>
    </div>
);
export default ReviewBlurbs;