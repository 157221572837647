import React from "react";

import { Articles, Interviews, Reviews, Podcasts, Footer, Videos} from "../../container";
import "../../App.css";

const MediaPage = () => {
    return (
        <div className="page">
            
            <Reviews/>
            <Interviews/>
            <Podcasts/>
            <Articles/>
            <Footer/>
        </div>
    );
};

export default MediaPage;