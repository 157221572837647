import React, { useEffect, useState} from 'react'
import { BrowserRouter, Routes, Route, Link, RedirectFunction, useLocation, useParams, NavLink } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import axios from 'axios';

import {Creators, Footer, Gallery, Header, UpcomingShows, SupportUs, Articles, Contact, Collaborators} from "./container";
import {Navbar, TicketButton, SocialIcons, DonateFixed} from "./components";
import { HomePage, TicketsPage, TeamPage, SupportPage, MediaPage, GalleryPage, ContactPage, AboutPage } from './pages';
import "./App.css";

/*function App() {
    const [data, setData] = useState([]);
    useEffect(() => {
      // Make server request to fetch data
      axios.get('/api/data') // Update the endpoint as needed
        .then(response => {
            setData(response.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);
    return (
        <div>
            <div className='nav'>
                <Navbar/>
            </div>
            <div className='myapp'>
                <BrowserRouter>
                    <Routes>
                                <Route path="/" element={<HomePage/>} />
                                <Route path="/tickets" element={<TicketsPage/>} />
                                <Route path="/tour" element={<TicketsPage/>} />
                                <Route path="/about" element={<HomePage/>} />
                                <Route path="/team" element={<TeamPage/>} />
                                <Route path="/support" element={<SupportPage/>} />
                                <Route path="/media" element={<MediaPage/>} />
                                <Route path="/gallery" element={<GalleryPage/>} />
                                <Route path="/contact" element={<ContactPage/>} />
                                <Route path="*" element={<HomePage/>} />
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
        
    );
}

export default App;*/

function App() {
    const location = useLocation();
    return(
        <div>
            <div className='nav'>
                <Navbar/>
            </div>
            <div className='myapp' var={{backgroundcolor:"var(--color-darkblack)"}}>
                <TransitionGroup component={null}>
                    <CSSTransition key={location.key} classNames="fade" timeout={300} var={{backgroundcolor:"var(--color-darkblack)"}}>
                        <Routes location={location}>
                            <Route path="/" element={<HomePage/>} />
                            <Route path="/tickets" element={<TicketsPage/>} />
                            <Route path="/tour" element={<TicketsPage/>} />
                            <Route path="/about" element={<AboutPage/>} />
                            <Route path="/team" element={<TeamPage/>} />
                            <Route path="/support" element={<SupportPage/>} />
                            <Route path="/media" element={<MediaPage/>} />
                            <Route path="/gallery" element={<GalleryPage/>} />
                            <Route path="/contact" element={<ContactPage/>} />
                            <Route path="*" element={<HomePage/>} />
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <SocialIcons/>
            <TicketButton/>
            <DonateFixed/>
        </div>
    );
}

export default App