import React from 'react';
import { useCollapse } from 'react-collapsed';
import './Articles.css';
import {MdArrowForward, MdArrowUpward, MdArrowDownward, MdExpandMore, MdExpandLess} from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { Item } from '../../components';
import { images, pressimages, data } from '../../constants';
import { FaItalic } from 'react-icons/fa';

function Articles() {
        const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
        const expandString = <div class="headerContent" style={{backgroundColor:"rgba(42, 39, 39, 0.449)", borderColor:"var(--color-golden)", borderRadius:"15px", padding:"1rem", border:"30px"}}><h1 className="headtext__cormorant-article-title" ><i>DISSONANCE</i> in the Papers</h1><div class="expandIcon"><MdExpandMore style={{fontSize:"50px"}}/></div></div>
        const collapseString = <div class="headerContent" style={{color:"var(--color-darkblack)", backgroundColor:"var(--color-golden)", borderColor:"var(--color-golden)", borderRadius:"15px", padding:"1rem" }}><h1 className="headtext__cormorant-article-title"style={{color:"var(--color-black)"}}><i>DISSONANCE</i> in the Papers</h1><div class="expandIcon"><MdExpandLess style={{fontSize:"50px"}}/></div></div>
    return(
        <div className="app__bg app__articles section__padding_links" id="articles">
            <div className="collapsible">
                <div className="header" {...getToggleProps()}>
                    {isExpanded ? collapseString : expandString }
                </div>
                <div {...getCollapseProps()}>
                    <div className="app__articles-feature-container">
                        <div class="grid">
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceOrlandoWeekly} alt="Orlando Weekly Article" loading='lazy'/>s
                                    <div class="card__content">
                                        <h1 class="card__header">Orlando Weekly</h1>
                                        <p class="card__text">"Can a white woman and a Black woman have an honest conversation about race?"</p>
                                        <a class="card__btn" href="https://www.orlandoweekly.com/arts/can-a-white-woman-and-a-black-woman-have-an-honest-conversation-about-race-34930143" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceParkAveMagazine} alt="Park Ave Magazine Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Park Ave Magazine</h1>
                                    <p class="card__text">"Interview with Dissonance Playwrights and Actors Marci Duncan and Kerry Sandell"</p>
                                    <a class="card__btn"href="https://parkavemagazine.com/interview-with-dissonance-playwrights-and-actors-marci-duncan-and-kerry-sandell/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceOrlandoSentinel} alt="Orlando Sentinel Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Orlando Sentinel</h1>
                                    <p class="card__text">"Dissonance digs deep: Can a Black woman and white woman talk race and stay friends?"</p>
                                    <a class="card__btn" href="https://www.orlandosentinel.com/2023/08/15/dissonance-play-ritz-theater-sanford-race/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceNWFDailyNews} alt="Northwest Florida Daily News Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Northwest Florida Daily News</h1>
                                    <p class="card__text">"Dissonance, a play set in Pensacola about friendship and race, comes to Niceville"</p>
                                    <a class="card__btn"href="https://www.nwfdailynews.com/story/entertainment/arts/2023/07/06/dissonance-play-pensacola-friendship-race-niceville/70384248007/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceISSU} alt="InWeekly Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Inweekly</h1>
                                    <p class="card__text">"Difficult Conversations with Friends"</p>
                                    <a class="card__btn" href="https://issuu.com/inpublisher/docs/inweekly_aug_25_2022_issue/20" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonancePNJ} alt="Pensacola News Journal Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Pensacola News Journal</h1>
                                    <p class="card__text">"Can friendship survive a frank discussion about race? Pensacola play explores idea."</p>
                                    <a class="card__btn"href="https://www.pnj.com/story/news/local/2023/02/10/dissonance-at-pensacola-little-theatre-explores-how-race-discussions-impact-friendships/69878272007/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceWUWF} alt="WUWF Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">WUWF</h1>
                                    <p class="card__text">"Locally produced play explores race in a new dialogue""</p>
                                    <a class="card__btn"href="https://www.pnj.com/story/news/local/2023/02/10/dissonance-at-pensacola-little-theatre-explores-how-race-discussions-impact-friendships/69878272007/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="grid__item">
                            <div class="card">
                                <img class="card__img" src={pressimages.dissonanceTallahasseeDemocrat} alt="Tallahassee Democrat Article" loading='lazy'/>
                                <div class="card__content">
                                    <h1 class="card__header">Tallahassee Democrat</h1>
                                    <p class="card__text">"FAMU develops 'Dissonance': New play demonstrates legacy of leading with love"</p>
                                    <a class="card__btn"href="https://www.tallahassee.com/story/entertainment/2022/08/13/famu-develops-dissonance-theatrical-legacy-leading-love-play-essential-theatre/10304096002/" target="_blank" rel="noopener noreferrer">Read <span>&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div> 
            </div>
        </div>       
    </div>
    );
}
export default Articles;