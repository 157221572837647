import React from 'react';
import { NavLink } from 'react-router-dom';

import {MdArrowForward} from "react-icons/md";
import './TicketButton.css';

const TicketButton = () => (
    <div id="order-button">
        <button className="order-tickets"><NavLink to="/tickets">Order Tickets <MdArrowForward fontSize="15px"/></NavLink></button>
    </div>
);

export default TicketButton;