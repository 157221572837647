import React from 'react';
import { FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import { FaTiktok, FaYoutube } from 'react-icons/fa';
import { ContactButton, MailingListButton} from '../../components';
import { images } from '../../constants';
import './Contact.css';

const Contact = () => (
    <div className="contact" id="contact">
        <h1 className="headtext__cormorant">Contact</h1>
        <div className="app__footer-links_logo">
            <div className="app__bg section__padding" style={{textAlign:'center', alignContent:'center', alignItems:'center', backgroundColor:'var(--color-black)'}}>
                <div className="app__bg app__wrapper" style={{backgroundColor:'var(--color-black)'}}>
                    <div className="app__aboutContact">
                        <p className="p__opensans_contact"style={{fontSize:'1.75rem', fontWeight:'600'}}>Interested in bringing <i>DISSONANCE</i> to your community?</p>
                        <p className="p__opensans_contact" style={{color:'white', fontWeight:'300'}}>Email contact@dissonanceplay.com or call (347) 523-0452 to schedule a conversation with our team to learn more!</p>
                    </div>
                    <div className='contact_image_container'>
                        <img src={images.diss_promo_5} className="contactUs_PromoImage"loading="lazy" alt="Show Image" />
                    </div>
                </div>
            </div>
            <ContactButton/>
            <div className="app__footer-links_icons">
                <a href='https://www.youtube.com/@dissonancetheplay'><FaYoutube/></a>
                <a href='https://www.facebook.com/Dissonancetheplay>'><FiFacebook /></a>
                <a href='https://www.instagram.com/dissonance_the_play/'><FiInstagram /></a>
                <a href='https://www.tiktok.com/@dissonance_the_play'><FaTiktok/></a>
            </div>
        </div>
    </div>
);

export default Contact;