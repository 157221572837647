import React from "react";

import { UpcomingShows, TourHistory, SupportUs, Collaborators, Gallery, Footer } from "../../container";
import { ReviewBlurbs } from "../../components";
import "../../App.css";

const TicketsPage = () => {
    return (
        <div className= "page">
            <UpcomingShows/>
            <ReviewBlurbs/>
            <SupportUs/>
            <Collaborators/>
            <TourHistory/>
            <Footer/>
        </div>
    );
};

export default TicketsPage;