import React from 'react';
import './Footer.css';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { FaTiktok } from 'react-icons/fa';
import { ContactButton, MailingListButton } from '../../components';

const Footer = () => (
    <div className="footer" id="footer">
        <div className="footer__copyright">
            <MailingListButton/>
            <p className="p__opensans">2024 - Dissonance the Play. All rights reserved.</p>
        </div>
    </div>
);

export default Footer;