import React from 'react';
import { useCollapse } from 'react-collapsed';
import './Podcasts.css';
import {MdArrowForward, MdArrowUpward, MdArrowDownward, MdExpandMore, MdExpandLess} from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { Item } from '../../components';
import { images, pressimages, data } from '../../constants';

function Podcasts() {
        const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
        const expandString = <div class="headerContent" style={{backgroundColor:"rgba(42, 39, 39, 0.449)", borderColor:"var(--color-golden)", borderRadius:"15px", padding:"1rem", border:"30px"}}><h1 className="headtext__cormorant-podcast-title" >Podcast and Interview Appearances</h1><div class="expandIcon"><MdExpandMore style={{fontSize:"50px"}}/></div></div>
        const collapseString = <div class="headerContent" style={{color:"var(--color-darkblack)", backgroundColor:"var(--color-golden)", borderColor:"var(--color-golden)", borderRadius:"15px" }}><h1 className="headtext__cormorant-podcast-title"style={{color:"var(--color-black)"}}>Podcast and Interview Appearances</h1><div class="expandIcon"><MdExpandLess style={{fontSize:"50px"}}/></div></div>
    return(
        <div className="app__bg app__podcasts section__padding_links" id="podcasts">
            <div className="collapsible">
                <div className="header" {...getToggleProps()}>
                    {isExpanded ? collapseString : expandString }
                </div>
                <div {...getCollapseProps()}>
                    <div className="app__podcasts-feature-container">
                        <div class="grid">
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceblankproject} alt="Blank Project Podcast Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">The Blank Project Podcast</h1>
                                        <p class="card__text"><i>DISSONANCE</i>, The Play (feat. Marci Duncan & Kerry Sandell)</p>
                                        <a class="card__btn" href="https://open.spotify.com/episode/10qAqVm2XQUfMteDeONPBb?si=xO_yT81RSfqrhmAb0YAT1A" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonancebBlackTheatreNetwork} alt="Black Theatre Network Podcast Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Black Theatre Network</h1>
                                        <p class="card__text"><i>DISSONANCE</i>, part of July Literary Salon</p>
                                        <a class="card__btn" href="https://www.facebook.com/watch/live/?ref=watch_permalink&v=192245777149539" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceWomenIgnitingChange} alt="Women Igniting Change Podcast Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Women Igniting Change</h1>
                                        <p class="card__text">Beyond Black and White: The Women Behind <i>DISSONANCE</i></p>
                                        <a class="card__btn" href="https://womenignitingchange.com/podcast/ep-11-beyond-black-and-white-the-women-behind-dissonance/" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceFriendshipable} alt="Friendshipable Podcast Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Friendshipable</h1>
                                        <p class="card__text">Friendship and race with Marci Duncan and Kerry Sandell</p>
                                        <a class="card__btn" href="https://open.spotify.com/episode/2Gqcf6pH3Nh9irly2IjTUh" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceLegacyTalks} alt="Legacy Talks with Angie Juzang Podcast Interview" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Legacy Talks with Angie Juzang</h1>
                                        <p class="card__text"><i>DISSONANCE</i></p>
                                        <a class="card__btn" href="https://youtu.be/5sJo8NoypJg?si=mLl5D5-6R9prpr9uh" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="grid__item">
                                <div class="card">
                                    <img class="card__img" src={pressimages.dissonanceOnstageOffstage} alt="Onstage/Offstage Theatre Podcast" loading='lazy'/>
                                    <div class="card__content">
                                        <h1 class="card__header">Onstage/Offstage Theatre Podcast</h1>
                                        <p class="card__text">Marci J. Duncan & Kerry Sandell - <i>DISSONANCE</i></p>
                                        <a class="card__btn" href="https://onstageoffstage.podbean.com/e/176-marci-j-duncan-kerry-sandell-dissonance/" target="_blank" rel="noopener noreferrer">Listen <span>&rarr;</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div> 
                </div>
            </div>       
        </div>
    );
}
export default Podcasts;