import React from 'react';
import './WhatPeopleAreSaying.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

const WhatPeopleAreSaying = () => (
    <div className="wpas_container">
        <div className="app__wpas-title">
            <h1 className="headtext__cormorant">What People Are Saying</h1>
        </div>
        <div className="wpas_video_container">
            <iframe className="wpas_youtube" style={{minWidth:"300px" }} loading='lazy' src="https://www.youtube-nocookie.com/embed/3Ct3VYR4EBw?si=zeVK3yCA77VGZyzv" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowFullScreen></iframe>
        </div>
        
    </div>
);
export default WhatPeopleAreSaying;