import React from 'react';

import './DonateButton.css';

const DonateButton = () => (
    <div className="app__bg donate-button">
        <button className="donate"><a href = "https://www.gofundme.com/f/dissonance-the-play-southern-tour" target="_blank" rel="noopener noreferrer">Donate Now</a></button>
    </div>
);

export default DonateButton;