import belmont1 from '../assets/Belmont-1.avif';
import belmont2 from '../assets/Belmont-2.avif';
import poster from '../assets/Dissonance-Poster.avif';
import telogo from '../assets/Theatre-Evolution-Logo.avif';
import bg from '../assets/bg.avif';
import dislogo from '../assets/Dissonance-Logo.jpeg';
import disflyer from '../assets/Dissonance-Flyer.avif';
import marcidprofile from '../assets/marcidprofile.avif';
import kerrysprofile from '../assets/kerrysprofile.avif';
import jameswprofile from '../assets/jameswprofile.avif';
import aug18poster_famu from '../assets/aug18-20poster.avif';
import posterCropped from '../assets/postercropped.avif';
import diss_promo_1 from '../assets/diss_promo_1.avif';
import diss_promo_2 from '../assets/diss_promo_2.avif';
import diss_promo_3 from '../assets/diss_promo_3.avif';
import diss_promo_4 from '../assets/diss_promo_4.avif';
import diss_promo_5 from '../assets/diss_promo_5.avif';
import diss_promo_6 from '../assets/diss_promo_6.avif';
import collaborators from '../assets/collaborators.avif';
import threecordprod from '../assets/diss-threechord.avif';


export default {
    belmont1,
    belmont2,
    poster,
    telogo,
    bg,
    dislogo,
    disflyer,
    marcidprofile,
    kerrysprofile,
    jameswprofile,
    aug18poster_famu,
    posterCropped,
    diss_promo_1,
    diss_promo_2,
    diss_promo_3,
    diss_promo_4,
    diss_promo_5,
    diss_promo_6,
    collaborators,
    threecordprod
};