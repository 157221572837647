import React from 'react';

import './Creators.css';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const Creators = () => (
    <div className="app__bg app__creators" id="creators">
        <div className="app__bg app__creators-title">
            <h1 className="headtext__cormorant">Meet the Team</h1>
        </div>
            <div className="app__creators_profile_items">
                <div className="app__bg app__wrapper section__padding">
                    <div className="app__wrapper_img app__wrapper_img-reverse">
                        <img src={images.marcidprofile} alt="profile_image" loading='lazy'/>
                    </div>
                    <div className="app__wrapper_info">
                        <div className="headtext__cormorant">Marci Duncan</div>
                        <SubHeading title="Playwright/Actor" />
                        <div className="app__profile-content">
                            <p className="p__opensans_profile"> Marci J. Duncan earned a BA in performance at Florida A&M University and her Masters in Acting at the University of Florida. She studied television and film acting with the late Evonne Suhor at Orlando’s Art Sake Acting Studio, where she starred in three consecutive seasons of their Play De Luna Series.</p>
                            <p className="p__opensans_profile">Some of her extensive theatre credits include Romeo and Juliet, Two Trains Running, Crowns, From the Mississippi Delta, The Mountaintop, the world premiere of Ntozake Shange’s Lavender Lizards and Lilac Landmines: Layla’s Dream, and a one woman show called Florida Girls. Marci's film work includes "(Just) Another Day" co-starring Jamie Hector from HBO’s "The Wire" and "Life is not a Fairytale: The Fantasia Barrino Story" working alongside Debbie Allen and Fantasia Barrino. Most recently, Marci performed in Dwayne the Rock Johnson’s new television series "Young Rock."</p>
                            <p className="p__opensans_profile"> Marci is currently teaching acting and directing at the University of West Florida where she is the director of the acting program.  This is Marci’s playwriting debut.</p>
                        </div>
                    </div>
                </div>

                <div className="app__bg app__wrapper section__padding">
                    <div className="app__wrapper_img app__wrapper_img-reverse">
                        <img src={images.kerrysprofile} alt="profile_image" loading='lazy'/>
                    </div>
                    <div className="app__wrapper_info">
                        <h1 className="headtext__cormorant">Kerry Sandell</h1>
                        <SubHeading title="Playwright/Actor" />

                        <div className="app__profile-content">
                            <p className="p__opensans_profile">Kerry Sandell holds a Bachelor’s degree in Theatre Performance and a Master’s degree in Strategic Communication from the University of West Florida. She is passionate about connecting people through storytelling, which was the genesis for developing Dissonance with Marci.</p>
                            <p className="p__opensans_profile">A few of her past theatrical credits include M’Lynn in Steel Magnolias, Ruth in Collected Stories (which she also produced), the titular role in Medea, Kate in Dancing at Lughnasa, Meg in Crimes of the Heart, and Mary in On the Verge.</p>
                            <p className="p__opensans_profile">This is her first play collaboration - with more on the way.</p>
                        </div>
                    </div>
                </div>

                <div className="app__bg app__wrapper section__padding">
                    <div className="app__wrapper_img app__wrapper_img-reverse">
                        <img src={images.jameswprofile} alt="profile_image" loading='lazy'/>
                    </div>
                    <div className="app__wrapper_info">
                        <h1 className="headtext__cormorant">James Webb</h1>
                        <SubHeading title="Director" />

                        <div className="app__profile-content">
                            <p className="p__opensans_profile">James Webb is a New York-based playwright, director, and educator, whose work is centered on the southern Black experience. </p>
                            <p className="p__opensans_profile">His plays include The Contract (Lorraine Hansberry National Playwriting Award); Mississippi Crossroad (O’Neill Semifinalist); The House of Jean-René; Wrestling With Angels, a hip-hop children’s musical; and Sibling Rivalry. His work has been presented at the National Black Theatre Festival, Ensemble Studio Theatre/LA, Kraine Theatre NYC, FAMU Essential Theatre, Confrontation Theatre, and Producer’s Club.</p>
                            <p className="p__opensans_profile">Webb is an Assistant Professor of Theatre and Communications at Bronx Community College and spends his summers as a resident playwright for the Irene C. Edmonds Youth Theatre.</p>
                        </div>
                    </div>
                </div>


            </div>
    </div>
);

export default Creators;