import React from 'react';

import './SocialIcons.css';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { FaTiktok, FaYoutube} from 'react-icons/fa';

const SocialIcons = () => (
    <div id="social-icons">
        <div className="app__footer-links_icons_fixed">
            <div className='icon-box'>
                <a href='https://www.youtube.com/@dissonancetheplay' target="_blank" rel="noopener noreferrer"><FaYoutube color="#CD201F"/></a>
            </div>
            <div className="icon-box">
                <a href='https://www.facebook.com/Dissonancetheplay' target="_blank" rel="noopener noreferrer"><FiFacebook color="#4267B2"/></a>
            </div>
            <div className ="icon-box">
                <a href ="https://www.instagram.com/dissonance_the_play/" target="_blank" rel="noopener noreferrer"><FiInstagram color="#8a3ab9"/></a>
            </div>
            <div className ="icon-box">
                <a href ="https://www.tiktok.com/@dissonance_the_play" target="_blank" rel="noopener noreferrer"><FaTiktok color="#69C9D0"/></a>
            </div>
        </div>
    </div>
);

export default SocialIcons;