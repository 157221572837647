import React from 'react';

import { SubHeading, QuotesCarousel, WhatPeopleAreSaying } from '../../components';
import { Contact } from '../../container';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
    <div className="header" id="home">
        <div className="app__header app__wrapper section__padding">
            <div className="app__wrapper_info" style={{ alignItems: 'center'}}>
                <div className="navbar_spacer"></div>
                <SubHeading title="Two friends. Twenty years. One difficult conversation."/>
                <h1 className="app__header-h1"><i>DISSONANCE</i></h1>
                <p className="p__opensans_profile" style={{ margin: '1rem 0', textAlign:'center', alignItems:'center', fontSize:'18px' }}>
                    We are Marci Duncan and Kerry Sandell, Florida-based actor-writers in a new hit play called <i>DISSONANCE</i>, a 90-minute two-person drama that asks the question:
                </p>
                <p className="p__opensans_profile" style={{ margin: '0 0', color: 'var(--color-red)', width:'80%', textAlign:'center', alignItems:'center', fontSize:'22px'}}>
                    “Can a Black woman and White woman—who have been friends for 20 years—have an open, honest conversation about race and have their friendship survive?”
                </p>
                <QuotesCarousel/>
            </div>
            <div className="app__wrapper_img">
                <img src={images.poster} className="homepage_hero" alt="header_img" />
            </div>
        </div>
        <WhatPeopleAreSaying/>
    </div>
);

export default Header;