import React from 'react';

import {MdArrowForward} from "react-icons/md";
import './DonateFixed.css';

const DonateFixed = () => (
    <div id="donate-fixed">
        <button className="donate-fixed-button"><a href = "https://www.gofundme.com/f/dissonance-the-play-southern-tour" target="_blank" rel="noopener noreferrer">Donate <MdArrowForward fontSize="15px"/></a></button>
    </div>
);

export default DonateFixed;